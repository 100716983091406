<template>
<a href="https://katsumata-taiya.com">
  <div class="hello">
    <img class="img_katsumatatire" alt="time" src="../assets/katsumatataiya_mockup.webp">
    <h2 class="katsumatatire_h2">ホームページ</h2>
    <p class="katsumatatire_p">
      店舗の雰囲気をもとに作成した<br>
      シンプルなWebページです。
    </p>
  </div>
</a>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.hello{
  margin-bottom: 100px;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.img_katsumatatire {
  border-radius: 20px;
  width: 80%;
  mix-blend-mode: multiply;
}
.katsumatatire_p{
  font-size: 13px;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  padding-left: 25%;
  padding-right: 25%;
}
.katsumatatire_h2{
  font-size: 0.9rem;
}
a{
  text-decoration: none;
  color: #2c3e50;
}
</style>
