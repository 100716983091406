<template>
<a href="https://notify-bot.line.me/ja/">
  <div class="hello">
    <img class="img_notice" alt="time" src="../assets/linenotice_mockup.webp">
    <h2 class="notice_h2">{{msg}}</h2>
    <p class="notice_p">
    WebクローリングにてPOS情報を取得したのち
    LINE Notice APIを使用して売上の報告を
    業務終わりに通知するシステムです。<br><br>
    通知は音無しのサイレントが可能です。
    </p>
  </div>
</a>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.hello{
  margin-bottom: 100px;
}

h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.img_notice {
  border-radius: 20px;
  width:80%;
  mix-blend-mode: multiply;
}
.notice_p{
  font-size: 13px;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  padding-left: 25%;
  padding-right: 25%;
}
.notice_h2{
  font-size: 0.9rem;
}
a{
  text-decoration: none;
  color: #2c3e50;
}
</style>
