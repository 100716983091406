<template>
  <div class="home">
    <div class="home_header">
      <img class="img_profile" src="../assets/profile.png">
      <nav>
        <router-link class="home_link" to="/">Top</router-link> |
        <router-link class="home_link" to="/about">About</router-link> |
        <router-link class="home_link" to="/developer">Developer</router-link>
      </nav>
      <h1>AI・Web・LINE</h1>
      <p>業務を効率化するシステムを作成します。</p>
    </div>
    <div class="home_summary">
      <HelloWorld msg="Summary"/>
    </div>
    <div class="home_about">
      <About msg="開発者について。"/>
      <router-link class="home_about_a" to="/developer">More</router-link>
    </div>
    <div class="home_section">
      <section id="line_link">
        <Line_api msg="LINEシステム"/>
      </section>
      <section id="web_link">
        <Web_taiyakan msg="Web"/>
      </section>
      <section>
        <Web_katsumatatire msg="シンプルなWebサイト (勝間田タイヤ)"/>
      </section>
      <section id="auto_link">
        <Auto_youtube msg="自動化"/>
      </section>
      <section>
        <Notice_line msg="売上報告 × LINE通知システム"/>
      </section>
      <section id="ai_link">
        <Notedropq msg="AI関連"/>
      </section>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'
import Notedropq from '@/components/Notedropq.vue'
import About from '@/components/About.vue'
import Auto_youtube from '@/components/Auto_youtube.vue'
import Notice_line from '@/components/Notice_line.vue'
import Line_api from '@/components/Line_api.vue'
import Web_taiyakan from '@/components/Web_taiyakan.vue'
import Web_katsumatatire from '@/components/Web_katsumatatire.vue'

export default {
  name: 'HomeView',
  components: {
    HelloWorld,
    About,
    Notedropq,
    Auto_youtube,
    Notice_line,
    Line_api,
    Web_taiyakan,
    Web_katsumatatire
  },
  created() {
    if (this.$browserBackFlg) {
      location.reload();
    }
    else {
    //
    }
  }
}
</script>

<style>
.home_about_a {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px;
  width: 160px;
  height: 50px;
  color: #333;
  font-size: 13px;
  font-weight: 700;
  text-decoration: none;
  background-color: #90be70;
  border-radius: 40px;
  margin-top: 30px;
}

.home_about_a::after {
  content: '';
  width: 10px;
  height: 10px;
  border-top: 2px solid #2b550e;
  border-right: 2px solid #2b550e;
  transform: rotate(45deg);
}

.img_profile {
  border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
  width: 40%;
}
.home_header{
  padding-top: 90px;
  padding-bottom: 90px;
}
.home_about{
  padding-top: 90px;
  padding-bottom: 90px;
  text-align:left;
  margin-left: 30px;
}
.home_section{
  padding-top: 90px;
  padding-bottom: 90px;
}
.home_summary{
  padding-top: 90px;
  padding-bottom: 90px;
}
.home_link{
  font-size: 13px;
  color: black;
}
</style>
