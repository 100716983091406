<template>
  <div class="hello">
  <router-link class="about_summary" to="/about">
    <h1>{{ msg }}</h1>
    <img alt="time" src="../assets/outline_schedule_black_48dp.png">
    <p class=hello_p>
      創造的な時間を増やすために。
    </p>
  </router-link>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.about_summary{
  text-decoration: none;
  color: #2c3e50;
}
.hello_p{
  padding-top: 8px;
  color: #2c3e50;
}
</style>
