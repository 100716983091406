<template>
<a href="https://www.youtube.com/channel/UCpoL1M2BDm9knnWXJDsBWSg">
  <div class="hello">
    <h1>{{ msg }}</h1>
    <img class="img_auto" alt="time" src="../assets/youtubeauto_mockup.webp">
    <h2 class="auto_h2">グラフ動画 × Youtube自動アップロード</h2>
    <p class="auto_p">
    その日トレンドになったニュースを取得して
    ランキンググラフ動画を自動的に作成後
    Youtubeにアップロードするシステムです。
    </p>
  </div>
</a>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.hello{
  margin-bottom: 100px;
}

h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.img_auto {
  border-radius: 20px;
  width: 80%;
  mix-blend-mode: multiply;
  margin-bottom: -4rem;
}
.auto_p{
  font-size: 13px;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  padding-left: 25%;
  padding-right: 25%;
  margin-bottom: 2rem;
}
.auto_h2{
  font-size: 0.9rem;
}
a{
  text-decoration: none;
  color: #2c3e50;
}
</style>
