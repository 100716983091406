<template>
<router-link class="router_link_class" to="/lineminiapp">
  <div class="hello">
    <h1>{{ msg }}</h1>
    <img class="img_line" alt="time" src="../assets/linemini_mockup.webp">
    <h2 class="line_h2">発注システム × LINEミニアプリ</h2>
    <p class="line_p">
      商品をLINE上でオーダー可能にすることで
      ログインの必要やアプリダウンロードの
      手間を省くことができます。
    </p>
  </div>
</router-link>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.hello{
  margin-bottom: 100px;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.img_line {
  border-radius: 20px;
  width: 80%;
  object-fit: cover;
  object-position: 0 100%;
  mix-blend-mode: multiply;
}

.line_p{
  font-size: 13px;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  padding-left: 25%;
  padding-right: 25%;
}
.line_h2{
  font-size: 0.9rem;
}
.router_link_class{
  text-decoration: none;
  color: #2c3e50;
}
</style>
