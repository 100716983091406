import { render, staticRenderFns } from "./Notice_line.vue?vue&type=template&id=0e2b2c50&scoped=true&"
import script from "./Notice_line.vue?vue&type=script&lang=js&"
export * from "./Notice_line.vue?vue&type=script&lang=js&"
import style0 from "./Notice_line.vue?vue&type=style&index=0&id=0e2b2c50&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0e2b2c50",
  null
  
)

export default component.exports