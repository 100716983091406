<template>
<a href="https://note.com/dropq/n/na37f5c41f03c">
  <div class="hello">
    <h1>{{ msg }}</h1>
    <img class="img_dropq" alt="time" src="../assets/IMG_8535.jpg">
    <h2 class="dropq_h2">Reinforcement Deep Learning × trading</h2>
    <p class="dropq_p">
      Reinforcement Deep Learning (深層強化学習)は
      AI機械学習の手法の一つです。<br> <br>
      仮想のシミュレーション環境を構築し、ある目的を最大化するエージェントを作成します。<br>
      シミュレーション環境でのエージェントの行動を観察したり、実際に本番環境で動かせます。
    </p>
  </div>
</a>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.hello{
  margin-bottom: 100px;
  padding-top: 2rem; 
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.img_dropq {
  border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
  width: 70%;
}
.dropq_p{
  font-size: 13px;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  padding-left: 25%;
  padding-right: 25%;
}
.dropq_h2{
  font-size: 0.9rem;
}
a{
  text-decoration: none;
  color: #2c3e50;
}
</style>
